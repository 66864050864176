import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/Seo';

const InteriorPage = ({ location }) => (
  <Layout location={location}>
    <div className="page-wrapper-inner">
      <Seo
        title="About Us"
        description="Our professionals are here to help you define and meet your financial goals. Rosewood Family Advisors provides a diverse range of family office services including tax compliance..."
        slug={location.pathname}
      />
      {/* Main */}
      <section className="wrapper style1">
        <div className="container">
          <div id="content">

            {/* Content */}
            <article>
              <header>
                <h2>About Us</h2>
                <p>Our mission is to provide exceptional client service.</p>
              </header>

              <p>
              Our professionals are here to help you define and meet your financial goals. Rosewood Family Advisors provides a diverse range of family office services including tax compliance, bill pay and expense management, philanthropic advising, and estate planning. Our integrated suite of services are customized to fit the many needs of ultra-affluent individuals and their complex lifestyles. We assist in developing solutions for every phase of our client’s financial matters.
              </p>

              <p>
              Rosewood Family Advisors provides the following services:
              </p>

              <ul className="links">
                <li><Link to="/services/bill-pay-and-accounting/">Bill Pay and Accounting</Link></li>
                <li><Link to="/services/income-tax-planning-and-compliance/">Income Tax Planning and Compliance</Link></li>
                <li><Link to="/services/family-administrative-services/">Family Administrative Services</Link></li>
                <li><Link to="/services/estate-and-gift-planning/">Estate and Gift Planning</Link></li>
                <li><Link to="/services/philanthropic-consulting/">Philanthropic Consulting</Link></li>
              </ul>


            </article>

          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default InteriorPage;
